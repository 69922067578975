<template>
  <div class="page-content">
    <div class="container-fluid">
      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 class="mb-sm-0 font-size-18">Quản lý Tracking Paid Jobs</h4>

            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item">
                  <a href="javascript: void(0);">Admin Traodoicheo</a>
                </li>
                <li class="breadcrumb-item active">Quản lý Tracking Paid Jobs</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <!-- end page title -->
      <div class="row">
        <div class="col-12">
          <div class="card py-4">
            <div class="container-fluid">
              <div class="row">
                <form @submit.prevent="getLogsList" class="col-md-4 mb-4 mb-md-0">
                  <div class="form-group">
                    <label for="">Tìm theo ads_id </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="nhập ads_id  "
                      v-model="ads_id"
                    />
                  </div>
                </form>
                <form @submit.prevent="getLogsList" class="col-md-4 mb-4 mb-md-0">
                  <div class="form-group">
                    <label for="">Tìm theo ads_type </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="nhập ads_type  "
                      v-model="ads_type"
                    />
                  </div>
                </form>
                <form class="col-md-4 mb-4 mb-md-0">
                  <div class="form-group">
                    <label for="">Limit</label>
                    <select class="form-select" v-model="limit" @change="getLogsList">
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                      <option value="1000">1000</option>
                    </select>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <loading-vue v-if="loading" />
      <div class="row">
        <div class="col-md-12">
          <datatable
            :stt="true"
            :title="'Danh sách Tracking Paid Jobs'"
            :columns="columns"
            :rows="manipulatedLogs"
            :defaultPerPage="10"
          >
            <th slot="thead-tr-o" class="text-center" style="vertical-align: middle">Thao Tác</th>
            <td slot="tbody-tr-o" class="text-center">
              <button class="btn btn-success font-10 py-1 btn-xs">Action</button>
            </td>
          </datatable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import trackingApi from "@/api/tracking";
import Datatable from "@/components/datatable/Datatable.vue";
import LoadingVue from "@/components/loading/Loading.vue";
import { buildParamQuery } from "@/helpers/index";

export default {
  name: "TrackingPaidJobs",
  components: {
    Datatable,
    LoadingVue,
  },
  data() {
    return {
      ads_type: null,
      ads_id: null,
      limit: 50,
      logs: [],
      loading: false,
      columns: [
        { label: "Id", field: "id" },
        { label: "Ads Id", field: "ads_id" },
        { label: "Error", field: "error" },
        { label: "Quantity", field: "quantity", numeric: true },
        { label: "Success", field: "success" },
        { label: "Time Run", field: "time_run" },
        { label: "Ads Type", field: "ads_type" },
        { label: "Res", field: "res" },
        { label: "Check Type", field: "check_type" },
        { label: "Ngày tạo", field: "created_at", timeago: true },
        { label: "Ngày cập nhật", field: "created_at", timeago: true },
      ],
    };
  },
  computed: {
    manipulatedLogs() {
      return this.logs;
    },
  },
  created() {
    this.getLogsList();
  },
  methods: {
    async getLogsList() {
      const params = {
        ads_type: this.ads_type,
        ads_id: this.ads_id,
        limit: this.limit,
      };
      const queries = buildParamQuery(params);

      this.loading = true;
      const response = await trackingApi.paidJob.list(queries);
      this.loading = false;
      if (response.success) {
        this.logs = response.data;
      } else {
        this.$toastr.error(response.message);
      }
    },
  },
};
</script>

<style lang="scss">
.error-cell {
  border: 1px solid rgb(209, 209, 209);
  color: #495057;
  font-size: 12px;
  width: 300px;
  outline-color: rgb(125, 150, 204);
  border-radius: 6px;
  cursor: pointer;
}
</style>
